<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Egreso Administrativo - Editar</strong>

            <router-link  class="btn btn-light float-right btn-sm"  :to="{ path: '/egreso-administrativo/listar' }" append ><i class="fa fa-chevron-left"></i> Regresar</router-link>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" autocomplete="off" @submit.prevent="Validate">
              <b-row>

                <b-col md="4">
                  <b-form-group>
                    <label>Cliente:</label>
                    <v-select placeholder="Seleccione un cliente" class="w-100" :filterable="false" label="text" v-model="client" @search="SearchClients" :options="clients"></v-select>
                    <small v-if="errors.id_client" class="form-text text-danger" >Selccione un cliente</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Código:">
                    <b-form-input type="text"  class="text-center" readonly v-model="expense.code"></b-form-input>
                    <small v-if="errors.code"  class="form-text text-danger" >Ingrese un código</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Tipo:">
                    <b-form-select v-model="expense.id_expense_method" :options="expense_method"></b-form-select>
                    <small v-if="errors.id_expense_method"  class="form-text text-danger" >Seleccione un tipo</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha:">
                    <b-form-input readonly type="date" class="text-center" :min="expense.now_date" v-model="expense.date"></b-form-input>
                    <small v-if="errors.date"  class="form-text text-danger" >Ingrese una fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Metodo de Pago:">
                    <b-form-select v-model="expense.payment_method" :options="payment_method"></b-form-select>
                    <small v-if="errors.payment_method"  class="form-text text-danger" >Seleccione un metodo</small>
                  </b-form-group>
                </b-col>

                <b-col md="10">
                  <b-form-group label="Observación:">
                    <b-form-textarea v-model="expense.observation"></b-form-textarea>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Total:">
                    <b-form-input type="number" step="any" class="text-right" v-model="expense.total"></b-form-input>
                    <small v-if="errors.total"  class="form-text text-danger" >Ingrese un monto</small>
                  </b-form-group>
                </b-col>

                

                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>
                
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <ModalClients />
    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import DataCollection from "@/assets/js/DataCollection";// components
// components
import ModalClients from './../components/ModalClient'
import LoadingComponent from './../pages/Loading'
export default {
  name: "UsuarioAdd",
  props: ["id_expense"],
  components:{
      vSelect,
      ModalClients,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'Expense',
      role: 3,
      expense: {
        id_expense:'',
        id_user:'',
        id_client:'',
        id_branch_office:'',
        id_expense_method:'',
        code:'',
        payment_method:'008',
        date:moment(new Date()).local().format("YYYY-MM-DD"),
        now_date: moment(new Date()).local().format("YYYY-MM-DD"),
        observation:'',
        total:'',
        state:'',
      },
      // payment_method:[
      //   {value:'008', text:'EFECTIVO'},
      //   {value:'007', text:'CHEQUES'},
      //   {value:'001', text:'DEPÓSITO EN CUENTA'},
      //   {value:'003', text:'TRANSFERENCIA DE FONDOS'},
      // ],
      client: null,
      clients: [],
      expense_method:[],
      errors: {
        id_client: false,
        code: false,
        payment_method: false,
        date: false,
        total: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ListExpenseMethod();
    this.ViewExpense();
  },
  methods: {
    ListExpenseMethod,
    ViewExpense,
    SearchClients,
    EditExpense,
    Validate,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
    payment_method: function () {
      return DataCollection.MethodPayment();
    }
  },
};

function ViewExpense() {
  let me = this;
  let id_expense = je.decrypt(this.id_expense);
  let url = me.url_base + "expense/view/"+id_expense;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.expense.id_expense = response.data.result.id_expense;
        me.expense.id_client = response.data.result.id_client;
        me.expense.id_user = response.data.result.id_user;
        me.expense.id_branch_office = response.data.result.id_branch_office;
        me.expense.id_expense_method = response.data.result.id_expense_method;
        me.expense.code = response.data.result.code;
        me.expense.payment_method = response.data.result.payment_method;
        me.expense.date = response.data.result.date;
        me.expense.observation = response.data.result.observation;
        me.expense.total = response.data.result.total;
        me.expense.state = response.data.result.state;
        me.client = { value: response.data.result.id_client, text: response.data.result.client_name }
      }
    });
}

function ListExpenseMethod() {
  let me = this;
  let url = me.url_base + "expense/list-expenses-method";
  me.expense_method = [{ value: '', text:'- Seleccione una opcion - '}];
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      response.data.result.forEach(element => {
        me.expense_method.push({value: element.id_expense_method, text: element.name });
      });
    }

  });
}

function SearchClients(search, loading) {
  
  let me = this;
   let url = this.url_base + "client/search-actives";
   if (search !== "") {

    let data = {
      search : search
    };

     loading(true);
     axios({
       method: "POST",
       url: url,
       data: data,
       headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
     }).then(function (response) {
           me.clients = response.data.result;
           loading(false);
     })
   }
}


function EditExpense() {
  let me = this;
  let url = me.url_base + "expense/edit";
  me.expense.id_client = this.client.value;
  let data = me.expense;
  me.isLoading = true;
  axios({
    method: "PUT",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {
  this.errors.id_client = this.client == null ? true : false;
  this.errors.id_expense_method = this.expense.id_expense_method.length == 0 ? true : false;
  this.errors.code = this.expense.code.length == 0 ? true : false;
  this.errors.payment_method = this.expense.payment_method.length == 0 ? true : false;
  this.errors.date = this.expense.date.length == 0 ? true : false;
  this.errors.total = this.expense.total.length == 0 || parseFloat(this.expense.total) <= 0? true : false;

  if (this.errors.id_client) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.id_expense_method) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.code) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.payment_method) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.total) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }

  let me = this;
  Swal.fire({
    title: "Esta seguro de modificar el egreso administrativo ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.EditExpense();
    }
  });
}
</script>
